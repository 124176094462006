@font-face {
  font-family: 'Quicksand';
  src: url('https://rlp.tourismusnetzwerk.info/wp-content/themes/tourismusnetzwerk40/fonts/Quicksand-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('https://rlp.tourismusnetzwerk.info/wp-content/themes/tourismusnetzwerk40/fonts/Quicksand-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}