/*
Theme Name: TourismusNetzwerk 3.0
Description: TourismusNetzwerk 3.0
Version: 3.0
Author: Shapefruit AG - Christian Lersch
Author URI: http://shapefruit.de
License: GPL
*/

/* AUTOREN */

/* !!!!!!!!!!!!!!!!!!!!!! RPT VERSION !!!!!!!!!!!!!!!!!!!!!!  */

/* -----------------------------------------
   GLOBALE STYLES
----------------------------------------- */

iframe{
  display: none;
}

.youtube-iframe-placeholder{
  background-color: $gold !important;
  background: linear-gradient(45deg,#ab8767,#ab8767 10%,#ab8767 12%,#ac8868 41%,#b28f6f 52%,#bd9a7b 59%,#cdab8c 66%,#e1c0a2 71%,#f9d9bc 75%,#f7d7ba 76%,#e0bea1 82%,#d0ac90 87%,#c6a185 93%,#c39e82);
  .btn-primary{
    background-color: $tertiary !important;
    border: $tertiary !important;
    margin: 5px !important;
  }
}

html{
  font-size: 16px;
}

html body{
  font-size: 1rem;
  font-family: $font-family-base;
  overflow-x: hidden;
  &>.container-fluid{
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.fullpage, .singlepost{
  a{
    color: $tertiary;
  }
  a.btn{
    color: $secondary;
  }

  h1{
    font-size: 35*$px-base;
  }

  h2{
    font-size: 30*$px-base;
  }

  h3{
    font-size: 25*$px-base;
    font-weight: bold;
    word-break: break-word;
  }
  @media (min-width: 768px) {
    h1{
      font-size: 40*$px-base;
    }

    h2{
      //font-size: 30*$px-base;
    }

    h3{
      //font-size: 25*$px-base;
      margin-bottom: 15px;
    }
  }
  .blogcontentmain{
    padding-right:  20px;
    .post{
      .wp-caption{
        width: 100% !important;
        height: auto;
          img.size-large{
            width: 100%;
            height: auto;
          }
      }
    }
  }
  .postoptions{
    * {
      text-transform: uppercase !important;
      font-weight: normal !important;
    }
    .borderbox{
      border-top: solid 1px $quaternary;
      @include media-breakpoint-up(lg) {
        border-top: none;
        border-left: solid 1px $quaternary;
        padding-left: 20px;
      }
    }
  }
}

.avatarlogobox{
  margin-left: 30px;

  .avatar{
    img{
      border-radius: 500px;
    }
  }
  .rptlogo{
    margin-top: 50px;
  }
  .pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
      @include media-breakpoint-down(xs) {
        img{
          width: 100px;
          height: auto;
      }
    }
  }
}

#wpadminbar {
  overflow: hidden;
}

/* -----------------------------------------
   POST STYLES
----------------------------------------- */

.headlinebox{
  text-align: center;
}

.headlinebox2{
  text-align: center;
  h2{
    font-size: 22*$px-base;
  }
  svg path{
    fill: $tertiary !important;
  }
}

.btn{
  font-weight: 400;
  border-radius: 4px;
  padding: 6px;
}

.btnmehr{
  background-color: $primary;
  border-radius: 15px;
  padding: 3px 8px;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  width: 75px;
  display: block;
  text-align: center;
}


.gimmemyarrow{
  margin-left: 5px;
  width: 17px;
  height: 14px;
  stroke: $secondary;
}

.fullpage{
  overflow: hidden;
  .gallery{
    img{
      width: 100%;
      height: auto;
      padding: 10px;
      border: none !important;
    }
  }
}

.titlebalken{
  height: 200px;
  background-color: #e3d1c1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1{
    color: $tertiary;
    font-weight: bold;
    font-size: 35*$px-base;
    @include media-breakpoint-down(xs) {
      font-size: 25*$px-base;
    }
  }
  h5{
    font-size: 16*$px-base;
  }
}

.weitereartikel{
  .crp_related{
    .crp_title{
      display: none;
    }
    .card .card-body{
      padding-top: 10px !important;
    }
  }
  .katbox{
    display: none;
  }
}

body > div > iframe{
  display: none !important;
}