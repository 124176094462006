
/* -----------------------------------------
   POST PAGE (single.php)
----------------------------------------- */

.boxauthor{
  background: rgb(175,156,114);
  background: linear-gradient(45deg,#ab8767,#ab8767 10%,#ab8767 12%,#ac8868 41%,#b28f6f 52%,#bd9a7b 59%,#cdab8c 66%,#e1c0a2 71%,#f9d9bc 75%,#f7d7ba 76%,#e0bea1 82%,#d0ac90 87%,#c6a185 93%,#c39e82);
  padding: 30px;

  a{
    color: $primary;
  }
}

.authorinfobox{
  font-size: 13*$px-base;
}

.authorname{
  text-transform: uppercase;
  color: #ffffff !important;

  span, a{
    font-weight: bold;
    color: #ffffff !important;
  }
}

html body .singlepost .categories{
  text-transform: uppercase;
  a{
    font-weight: bold !important;
    color: $primary;
  }
}

hr {
  border: none !important;
  border-bottom: solid 1px $primary !important;
}

/* -----------------------------------------
   RELATED POSTS
----------------------------------------- */

.related_post_title{
  display: none;
}

.related_post{
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: stretch;
}

.related_post li {
  background-color: $primary;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 5px;
  margin-bottom: 20px;

  * {
    color: #ffffff;
    text-align: left;
  }

  img {
    width: 100%;
    height: auto;
  }

  small{
    padding: 5px 20px !important;
    text-transform: uppercase;
  }

  .wp_rp_title {
    font-size: 16*$px-base;
    margin: 0;
    padding: 5px 20px !important;
    margin-bottom: 20px;
  }
}



@include media-breakpoint-down(sm){
  .related_post{
    display: block;

    li{
      width: 100%;
    }
  }
}

/* -----------------------------------------
   Copyrigth
----------------------------------------- */

.single-post .thumbnailboxwithquelle{
  max-width: 100%;
  width: inherit;
  margin-bottom: 20px;
  height: auto;
}

.single-post img.size-full{
  width: 100%;
  height: auto;
}

.postgridview{
  .thumbnailboxwithquelle{
    padding-top: 69%;
    img{
      position: absolute;
    }
  }
  @include media-breakpoint-down(sm){
    padding-top: 0px !important;
  }
}

.isc_image_list_title{
  font-weight: bold;
}
.isc-source-text {
  background-color: $primary !important;
  color: rgb(255, 255, 255) !important;
  opacity: 0.6 !important;
}

.thumbnailboxwithquelle {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 150px;
  background-color: #ffffff;

  p {
    margin: 0 !important;
  }
  .isc-source{
   position: static;
    display: inline;
  }

  .isc-source-button{
    position: absolute;
    right: 10px;
    top: 0px;
    background-color: $primary;
    border-radius: 0px 0px 3px 3px;
    opacity: 0.6;
    font-weight: bold;
    padding: 10px;
    z-index:200;
    font-size: 18*$px-base;
    a{
      color: #ffffff;
    }
  }

  img{
    max-width: 100%;
    width: 100%;
    border-radius: 3px;
    top: 0;
    z-index: 1;
  }
}


//ALLGEMEINE STYLES
.fulldropdownshow{
  width: 100% !important;
}

.printericon{
  padding-top: 15px;
  svg{
    width: 45px;
  }
}

.ccchildpages{
  &.threecol{
    .ccchildpage{
      min-height: 290px;
    }
  }
  &.twocol{
    .ccchildpage{
      min-height: 390px;
    }
  }
  .ccchildpage{
    background-color: #8e1c3f !important;
    border-radius: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;


    *{
      color: #ffffff;
    }

    .cc-child-pages-thumb{
      order: -1;
      margin: 0px !important;
      width: 100% !important;
    }

    .isc-source{
      order: -1;
    }

    h3, .ccpages_excerpt, .ccpages_more{
      padding: 0px 15px;
    }

    h3{
      padding-top: 8px;
    }
    .ccpages_more a{
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      padding: 0.175rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-color: #ffffff;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      background-color: $primary;
      border-radius: 20px;
      font-style: normal;
    }
  }
}

a.blocklink:hover{
  text-decoration: none !important;
}
.tnteaser{
  min-height: 540px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-position: bottom, center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  padding: 0px;
  *{
    color: #ffffff;
    text-decoration: none !important;
  }
  .text{
    background-image: url('images/teaser-back-fader.png');
    padding: 20px;
  }
  .date{
    font-size: 13*$px-base;
    font-weight: bold;
    padding-bottom: 10px;
    display: inline-block;
  }
  h3{
    font-size: 25*$px-base;
    font-weight: normal;
  }
  .buttonbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .button{
      display: inline;
      background-color: $tertiary;
      border-radius: 3px;
      padding: 10px 10px 10px 20px;
      align-self: start;
      font-weight: bold;
      font-size: 14*$px-base;
      &:after{
        content: ' ';
        background-image: url('images/icons/pfeil-rechts.svg');
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
        margin-left: 10px;
        display: inline-block;
      }
      &:hover{
        background-color: $tertiaryhover;
      }
    }
  }
}

.tnicon{
  text-align: center;
  svg {
    width: 75px;
    height: 75px;
    path{
      fill: $gold;
    }
  }
}
.goldenbackground{
  svg path{
    fill: $tertiary;
  }
}

.tnbutton{
  display: flex;
  a{
    display: inline-block;
    display: inline;
    background-color: $primary;
    border-radius: 3px;
    padding: 10px 10px 10px 20px;
    align-self: start;
    font-weight: bold;
    font-size: 14*$px-base;
    color: #ffffff;

    .iconpfeil{
      height: 12px;
      width: 12px;
      margin-left: 10px;
      display: inline-block;
    }
    &:hover{
      text-decoration: none !important;
    }
  }
  a.farbe01{
    background-color: $primary;
    &:hover{
      background-color: $primaryhover;
    }
  }
  a.farbe02{
    background-color: $tertiary;
    &:hover{
      background-color: $tertiaryhover;
    }
  }
  a.farbe03{
    background-color: $gold;
    &:hover{
      background-color: #af9c71;
    }
  }
  a.weiss{
    background-color: #ffffff;
    color: $tertiary !important;
    .iconpfeil svg *{
      stroke: $tertiary !important;
    }
  }
  &.right{
    justify-content: flex-end;
  }
  &.center{
    justify-content: center;
  }
  &.left{
    justify-content: start;
  }
}

.blogcontentmain .category-marktforschung .tnbutton .button{
  padding-left: 10px;
}

html body .vc_grid.vc_row.vc_grid-gutter-35px .vc_grid-item{
  padding-right: 45px;
  padding-bottom: 45px;
}

.blogbeitraege{
  .vc_pageable-slide-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .vc_grid-item-mini.vc_clearfix,
  .vc_gitem-animated-block,
  .vc_gitem-zone,
  .vc_gitem-zone-a .vc_gitem-zone-mini,
  .vc_gitem-zone-a .vc_gitem-row-position-top,
  {
    height: 100%;
  }
}

.postgridview{
  .marginbottomspacer{
    margin-bottom: 60px;
  }
  .card{
    -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    border-radius: 3px;
    height: 100%;
    margin: 20px;
    padding: 10px;

    .card-img-top{
      transform: scale(1.15);
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: 3px;
    }

    .thumbnailboxwithquelle .isc-source-button{
      top: -16px;
      bottom: inherit;
    }

    .card-text,.card-text * {
      color: $primary;
    }
    .card-title *{
      color: $primary;
      font-weight: bold;
    }
    .card-body{
      margin-top: 11px;
    }
    .text-muted{
      font-size: 16*$px-base;
    }
    .katbox{
      text-align: right;
      a{
        background-color: $quaternary;
        border-radius: 0px 0px 3px 3px;
        color: #ffffff;
        display: inline-block;
        padding: 5px 10px;
        font-size: 12*$px-base;
        text-transform: uppercase;
        min-height: 37px;
        display: inline-flex;
        align-items: end;
        font-weight: bold;
        text-decoration: none;

        &:hover{
          background-color: #9ca2a8 !important;
        }
      }
    }
    .card-footer{
      background-color: #ffffff;
    }
  }
}
.postgridschmall{
  h4{
    font-size: 19*$px-base;
  }
  .vc_gitem-row-position-top{
    .vc_gitem-col{
      min-height: 695px !important;
    }
  }
}
.tnpostgridbox{
  overflow: visible !important;

  .vc_gitem-row-position-top{
    position: relative !important;

    .vc_gitem-col{
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: 3px;
      min-height: 640px;

      h5{
        font-weight: bold;
      }
    }

    .wpb_single_image .vc_figure{
      display: block;
      text-align: center;
    }

    .wpb_single_image img{
        transform: scale(1.15);
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: 3px;
    }

    .vc_grid-filter {
      text-align: right !important;
      display: block !important;
    }
    .vc_gitem-post-data-source-post_categories{
      margin-bottom: 0px;
      span{
        background-color: $quaternary;
        border-radius: 0px 0px 3px 3px;
        color: #ffffff;
        display: inline-block;
        padding: 5px 15px;
        font-size: 14*$px-base;
        text-transform: uppercase;
      }
    }
    .vc_gitem-post-data-source-post_categories > div{
      display: none;
    }

    .vc_gitem-post-data-source-post_categories > div:first-of-type{
      display: block;
    }
    .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item:hover{
      background-color: transparent;
    }

  }
  .vc_gitem-row-position-top{
    .vc_btn3{
      display: inline-block;
      display: inline;
      background-color: $tertiary !important;
      border-radius: 3px;
      padding: 5px 10px 5px 20px;
      align-self: start;
      font-weight: bold;
      font-size: 14*$px-base;
      color: #ffffff;

      &:after{
        content: ' ';
        background-image: url('images/icons/pfeil-rechts.svg');
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
        margin-left: 10px;
        display: inline-block;
      }
      &:hover{
        text-decoration: none !important;
      }
    }
  }
}

.vc_grid-container .owl-carousel .owl-stage-outer{
  height: inherit !important;
  overflow: visible;
}

html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey>li>a, html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey>li>span,
html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey > li > a, html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey > li > span{
  background-color: $primary !important;
  font-size: 14*$px-base;
  padding: 5px;
}

.katbuttonbox{
  background-color: #ffffff;
  padding: 25px;

  div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .button{
    display: inline-block;
    background-color: $quaternary !important;
    border-radius: 0px 0px 3px 3px;
    padding: 5px 10px 5px 10px;
    margin: 6px 12px;
    align-self: start;
    font-weight: bold;
    font-size: 14*$px-base;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    text-decoration: none;

    &:hover{
      background-color: #9ca2a8 !important;
    }
  }

  .collapsebutton{
    border-radius: 0px;
    display: block;
    text-align: center;
    width: 100%;
    color: $primary;
    padding: 20px;
    background-color: $quaternary_light !important;
    text-decoration: none;
    font-weight: normal;
    .gimmemyarrow{
      stroke: $primary !important;
    }
  }

  @include media-breakpoint-up(sm){
    background-color: $quaternary_light;
    .collapsebutton{
      display: none;
    }
    .collapse{
      display: flex !important;
    }
  }
}

html body .flexslider .slides img{
  height: auto;
}